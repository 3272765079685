<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { TYPES as STAFF_TYPOLOGY_TYPES } from './store'
import { ROUTES as STAFF_TYPOLOGY_ROUTES } from './router'
import { hasPerm } from '@/components/PermissionHelper'

import ThuxListMixin from '../../../components/thux-list/ThuxListMixin'

import StaffTypologyTable from './StaffTypologyTable'
import StaffTypologyCommonFilters from './StaffTypologyCommonFilters'
import StaffTypologyEdit from './StaffTypologyEdit.vue'

export default {
  name: 'StaffTypologyList',
  mixins: [ThuxListMixin],
  components: {
    'component-table': StaffTypologyTable,
    'common-filters': StaffTypologyCommonFilters,
    'component-edit': StaffTypologyEdit,
    'component-detail': StaffTypologyEdit
  },
  data () {
    return {
      STAFF_TYPOLOGY_ROUTES,
      title: this.$t('Staff typologies'),
      rolePerm: ['organization_stafftypology_list'],
      actionEnablePermission: ['organization_stafftypology_enable'],
      actionDisablePermission: ['organization_stafftypology_disable'],
      searchFields: [
        { key: 'filter__code__icontains', placeholder: this.$t('Code'), type: 'text', col: 6 },
        { key: 'filter__name__icontains', placeholder: this.$t('Name'), type: 'text', col: 6 }
      ]
    }
  },
  computed: {
    ...mapGetters({
      ...STAFF_TYPOLOGY_TYPES.GENERIC.organization.stafftypology.LIST.GETTERS
    }),
    showList () {
      return this.$route.name === this.STAFF_TYPOLOGY_ROUTES.STAFF_TYPOLOGY_LIST
    },
    canSeeActionSelect () {
      return true
    },
    canAddInstance () {
      return hasPerm('organization_stafftypology_create')
    }
  },
  methods: {
    ...mapMutations({
      setSelectedList: STAFF_TYPOLOGY_TYPES.GENERIC.organization.stafftypology.LIST.MUTATIONS.setSelectedList,
      setSelectAll: STAFF_TYPOLOGY_TYPES.GENERIC.organization.stafftypology.LIST.MUTATIONS.setSelectAll
    }),
    ...mapActions({
      ...STAFF_TYPOLOGY_TYPES.GENERIC.organization.stafftypology.LIST.ACTIONS
    })
  }
}
</script>
